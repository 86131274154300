import React, { useState } from 'react';
import { Row, Col, Label, Input, Form, Button, Modal, ModalHeader, ModalFooter, ModalBody, Spinner } from 'reactstrap';
import _ from 'lodash';
import Axios from 'axios';
import { useParams } from 'react-router-dom';


export const SubmissionBlock = ({ fetchProject, openProject: { submissions }, openProject }) => {
    const [submission, setSubmission] = useState({
        submitterId: localStorage.getItem('id'),
        submitterName: localStorage.getItem('username'), notes: '', files: []
    });
    const [loading, setLoading] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [updateStatus, setUpdateStatus] = useState('')
    const params = useParams();
    const submissionWarning = () => {
        return (
            <Modal isOpen={showWarning} toggle={() => setShowWarning(!showWarning)} className='modal-class'>
                <ModalHeader><h4>Confirm Submission</h4></ModalHeader>
                <ModalBody>
                    {
                        loading ? <div className='p5'>
                            <h5>Submitting, please do not close this window</h5>
                            <p className='mt-2 mb-2'>{updateStatus}</p>
                        </div>: 
                        <p>Please confirm you wish to complete this submission. You will be unable to alter it afterwards.</p>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color='success' disabled={loading} onClick={() => uploadSubmission()}>{loading ? <Spinner color='info'/> : 'Yes, Submit'}</Button>
                    <Button disabled={loading} onClick={() => setShowWarning(!showWarning)}>No, Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }



    const uploadSubmission = async () => {
        setLoading(true);
        setUpdateStatus('Creating submission....')
        const results = await Axios.post(`/api/projects/submission`, { ...submission, projectId: params.id },
            { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        if (results.status === 201) {
            //upload files
            setUpdateStatus('Uploading files....')
            const formData = new FormData();
            _.map(submission.files, (f) => {
                formData.append("file", f);
            })
            formData.append("submissionId", results.data._id);
            formData.append("projectId", params.id);
            formData.append("submitterId", localStorage.getItem('id'));
            formData.append("submitterName", localStorage.getItem('username'))
            const fileResult = await Axios.post(`/api/projects/submission/files`, formData,
                {
                    headers: {
                        Authorization: `bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
            fetchProject();
           
            setUpdateStatus('Complete! Thank you for your submission');
            setTimeout(()=>{
                setShowWarning(!showWarning)
                setLoading(false)
            }, 1500)
        } else {
            setLoading(false)
        }
    }

    const renderAddSubmission = () => {
        return (
            <div>
                <Row className='mt-2'>
                    <Label className='mb-2'>Submit a proposal for this project</Label>
                    <Col>
                        <Label className='mb-1'>Notes</Label>
                        <Input type='textarea' onChange={(e) => setSubmission({ ...submission, notes: e.target.value })} />
                    </Col>
                </Row>
                <Form className='mt-2'>
                    <Row>
                        <Col className='upload-box'>
                            <Label className='mb-1 w-100'>Attach file(s)</Label>
                            <Input type='file' multiple onChange={(e) => setSubmission({ ...submission, files: [...e.target.files] })} />
                            <div className='w-100 button-box'>
                                <Button type='reset'>Reset</Button>
                                <Button color='success' disabled={!openProject.isActive} onClick={() => setShowWarning(!showWarning)}>Submit</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    const renderAlreadySubmitted = () => {
        return (
            <div className='submitted-wrapper'>
                <h4>You've already submitted for this job.</h4>
            </div>
        )
    }

    const renderProjectClosed = () => {
        return (
            <div className='submitted-wrapper'>
                <h4>Sorry this tender is no longer accepting submissions</h4>
            </div>
        )
    }

    const stateToRender = () => {
        let hasSubmitted = false;
        _.each(submissions, (s) => {
            if (s.submitterId === localStorage.getItem('id')) {
                hasSubmitted = true;
            }
        })
        if (!openProject.isActive) {
            return renderProjectClosed()
        }
        if (hasSubmitted) {
            return renderAlreadySubmitted()
        } else {
            return renderAddSubmission()
        }

    }

    return (
        <div>
            {stateToRender()}
            {submissionWarning()}
        </div>

    )
}