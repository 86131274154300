import React, { useState, useEffect } from 'react';
import {
    Container, Button, Row, Col, Label, Input, Progress, Spinner, Form, Alert,
    UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import _ from 'lodash';
import './style.css';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import Toggle from 'react-toggle';
import { FaCheckCircle } from 'react-icons/fa';
import moment from 'moment';

export const NewProject = () => {
    const [showCustomEmailModal, setShowCustomEmailModal] = useState(false);
    const [customEmailUser, setCustomEmailUser] = useState(null)
    const [customEmailsList, setCustomEmailsList] = useState([]);
    const [customEmail, setCustomEmail] = useState({userId: null, projectId: null, emailText: ''})
    const [specialityList, setSpecialityList] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [showProgressBar, setProgressBarVisibility] = useState(false);
    const history = useHistory();
    const [steps, setSteps] = useState(1);
    const [newProject, setNewProject] = useState({
        projectName: '', startDate: '', endDate: '',
        notes: '', files: [], awardedTo: {}, isActive: true, submissions: []
    });
    const [invitedUsers, setInvitedUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [emailText, setEmailText] = useState('A new tender has been made available to you please visit https://tenders.desmillar.co.nz to view.')
    const [uploadStatus, setUploadStatus] = useState(null);
    const [error, setError] = useState('');
    const [specialityFilter, setSpecialityFilter] = useState('All');

    const fetchUsers = async () => {
        const results = await Axios.get('/api/users/all', { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            let data = [..._.filter(results.data, (u) => {
                return u.userType !== 'admin' && u.userType !== 'globalAdmin';
            })]
            setUsers([...data]);
        } else {
            console.log('Failed to fetch users');
        }
    }

    const fetchSpecials = async () => {
        const results = await Axios.get('/api/speciality', { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            setSpecialityList([...results.data]);
        } else {
            console.log('Failed to fetch specials');
        }
    }

    useEffect(() => {
        fetchUsers();
        fetchSpecials();
    }, []);

    useEffect(() => {
        if (specialityFilter === 'All') {
            setFilteredUsers([]);
            return
        }
        setFilteredUsers([..._.filter(users, (u) => {
            return u.speciality === specialityFilter;
        })])
    }, [specialityFilter])

    const isValid = (field) => {
        const { projectName, startDate, endDate, notes } = newProject;
        if (field === 'projectName' && !_.isEmpty(projectName)) {
            return true;
        }
        if (field === 'startDate' && !_.isEmpty(startDate)) {
            return true;
        }
        if (field === 'endDate' && !_.isEmpty(endDate)) {
            return true;
        }
        return false;

    }

    const addInvitedUser = (check, user) => {
        if (check) {
            let users = [...invitedUsers];
            users.push(user)
            setInvitedUsers([...users]);
        } else {
            let updatedUsers = [..._.filter(invitedUsers, u => u._id !== user._id)];
            setInvitedUsers([...updatedUsers]);
            let emailList = [..._.filter(customEmailsList, email => {
                return email.userId !== user._id;
            })];
            setCustomEmailsList(emailList);
        }

    }

    const uploadProgress = (progress) => {
        const { total, loaded } = progress;
        console.log(total)
        console.log(loaded)
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
        setUploadPercentage(uploadPercentage.toFixed(2));
    }

    const createProject = async () => {
        // step 1 create project.
        setSteps(4);
        setUploadStatus('Creating Project....');
        const projectResult = await Axios.post(`/api/projects/create`, { ...newProject },
            { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        if (projectResult.status === 201) {
            // step 2 upload files to project.
            const project = projectResult.data;
            setUploadStatus('Uploading files....');
            const formData = new FormData();
            _.map(newProject.files, (f) => {
                formData.append("file", f);
            })
            formData.append("id", project._id);
            const fileResult = await Axios.post(`/api/projects/file/create`, formData,
                {
                    headers: {
                        Authorization: `bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: progressEvent => uploadProgress(progressEvent)
                });
            if (fileResult.status === 201) {
                // step 3 email invited users and update project.
                setUploadStatus('Emailing users....');
                const emailResult = await Axios.put(`/api/projects/update`, { ...newProject, _id: project._id, invitedUsers: [...invitedUsers], emailMessageList: [...customEmailsList], sendMail: true },
                    { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
                if (emailResult.status === 200) {
                    setUploadStatus('Complete! taking you back...');
                    setTimeout(() => {
                        history.push('/projects')
                    }, 1500)
                }
            }
        } else {
            setUploadStatus('Opps something went wrong...');
        }

    }

    const removeFiles = () => {
        setNewProject({ ...newProject, files: [] })
    }

    const changePage = (direction) => {
        if (direction === 'back' && steps !== 1) {
            setSteps(steps - 1)
        }
        if (direction === 'foward' && steps !== 3) {
            if (steps === 1) {
                if (!_.isEmpty(newProject.projectName)) {
                    const { startDate, endDate } = newProject;
                    if (moment(startDate).unix() < moment(endDate).unix()) {
                        setSteps(steps + 1)
                        setError(null);
                    } else {
                        setError('Please ensure the start date is before the end date.');
                    }
                } else {
                    setError('Please provide a project name.');
                }
            }
            if (steps === 2) {
                setSteps(steps + 1)
            }

        }
        if (direction === 'back' && steps === 1) {
            history.push('/projects')
        }
        if (direction === 'foward' && steps === 3) {
            if (steps === 3) {
                if (_.isEmpty(invitedUsers)) {
                    setError('Please invite at least one user');
                } else {
                    setError(null);
                    createProject()
                }
            }

        }
    }

    const saveCustomEmail = () => {
        let customEmailExists = false;
         _.each(customEmailsList, email => {
            if(email.userId === customEmailUser._id) {
                customEmailExists = true
            }
        })
        if(customEmailExists) {
            // need to overwrite existing with new
            let emailList = [..._.filter(customEmailsList, email => {
                return email.userId !== customEmailUser._id;
            })]
            emailList.push(customEmail);
            setCustomEmailsList(emailList)
        } else {
            let emailList = [...customEmailsList];
            emailList.push(customEmail)
            setCustomEmailsList(emailList)
        }
        setCustomEmail({userId: null, projectId: null, emailText: ''})
        setCustomEmail({userId: null, projectId: null, emailText: ''});
        setShowCustomEmailModal(!showCustomEmailModal);
    }

    const customEmailValue = (clickedUser) => {
        setCustomEmailUser(clickedUser);
        let customEmailExists = false;
        _.each(customEmailsList, email => {
            if(email.userId === clickedUser._id) {
                customEmailExists = true
            }
        });
        console.log(customEmailExists)
        console.log(customEmailUser);
        if(customEmailExists) {
            let emailList = [..._.filter(customEmailsList, email => {
                return email.userId === clickedUser._id;
            })]
            console.log('email list',emailList)
            setCustomEmail(emailList[0])
        } else {
            setCustomEmail({userId: null, projectId: null, emailText: ''})
        }
    }

    const customEmailModal = () => {
        if(!customEmailUser) return null;
        
        return (
            <Modal isOpen={showCustomEmailModal} toggle={() => setShowCustomEmailModal(!showCustomEmailModal)} centered={true} >
                <ModalHeader>
                    <h4 style={{color: '#333'}}>Custom Email for {customEmailUser.displayName}</h4>
                </ModalHeader>
                <ModalBody>
                    <Input type='textarea' disabled={true} value={`Hi ${customEmailUser.displayName},`} />
                    <Input style={{height: 150}} type='textarea' value={customEmail.emailText} placeholder='Enter custom body text' onChange={(e) => setCustomEmail({userId: customEmailUser._id, projectId: null, emailText: e.target.value, sentTo: customEmailUser.email, displayName: customEmailUser.displayName}) } />
                    <Input style={{height: 100}} type='textarea' disabled={true} value={'Many Thanks\nDes Millar Construction\nhttps://tenders.desmillar.co.nz'} />
                </ModalBody>
                <ModalFooter>
                    <Button color='primary pw-reset' onClick={()=> saveCustomEmail()}>Save</Button>
                    <Button onClick={() => setShowCustomEmailModal(!showCustomEmailModal)}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }

    const renderBtnStatus = () => {
        if (steps === 1) {
            return '(1/3) Next';
        }
        if (steps === 2) {
            return '(2/3) Next';
        }
        if (steps === 3) {
            return '(3/3) Create'
        }
        if (steps === 4) {
            return <Spinner />
        }
    }

    console.log(customEmailsList);

    const showIcon = (user) => {
        //FaCheckCircle
        let customEmailExists = false;
        _.each(customEmailsList, email => {
            if(email.userId === user._id) {
                customEmailExists = true
            }
        });
        if(customEmailExists) {
            return <FaCheckCircle />
        } else {
            return null;
        }
    }

    const renderInviteUsers = () => {
        const list = !_.isEmpty(filteredUsers) || specialityFilter !== 'All' ? filteredUsers : users;
        return _.map(list, (u, index) => {
            return (
                <Col key={index} >
                    <Row className='invite-user-col'>
                        <Col>
                            <p>{u.displayName} - {u.speciality}</p>
                        </Col>
                        <Col lg={3} md={3} className='toggle-col'>
                            <Toggle checked={_.includes(invitedUsers, u)} onChange={(e) => addInvitedUser(e.target.checked, u)} />
                        </Col>
                        {
                            _.includes(invitedUsers, u) ?
                                <Col>
                                    <p className='email-action' onClick={() => {
                                        customEmailValue(u)
                                        setShowCustomEmailModal(!showCustomEmailModal)
                                    }}>Email {showIcon(u)} </p>
                                </Col>
                                : null
                        }
                    </Row>
                </Col>
            )
        })
    }

    const inviteAll = () => {
        let invUsers = [...invitedUsers];
        _.each(users, u => {
            if (!_.includes(invUsers, u)) {
                invUsers.push(u)
            }

        })
        setInvitedUsers([...invUsers]);

    }

    const renderSteps = () => {
        if (steps === 1) {
            return (
                <div>
                    <Row>
                        <Col>
                            <Label>Project name</Label>
                            <Input value={newProject.projectName} isValid={isValid('projectName')} invalid={!isValid('projectName')} onChange={(e) => setNewProject({ ...newProject, projectName: e.target.value })} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Start date</Label>
                            <Input type='date' isValid={isValid('startDate')} invalid={!isValid('startDate')}
                                onChange={(e) => setNewProject({ ...newProject, startDate: e.target.value })}
                                value={newProject.startDate} />
                        </Col>
                        <Col>
                            <Label>End date</Label>
                            <Input type='date' isValid={isValid('endDate')} invalid={!isValid('endDate')}
                                onChange={(e) => setNewProject({ ...newProject, endDate: e.target.value })}
                                value={newProject.endDate} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label className='mb-2'>Notes</Label>
                            <Input style={{ minHeight: 150 }} value={newProject.notes} type='textarea' onChange={(e) => setNewProject({ ...newProject, notes: e.target.value })} />
                        </Col>
                    </Row>
                </div>
            )
        }
        if (steps === 2) {
            return (
                <div>

                    <Form>
                        <Row>
                            <Label>Upload documents</Label>
                            <Input value={null} style={{ marginTop: 16 }} type='file' multiple={true} onChange={(e) =>
                                setNewProject({ ...newProject, files: [...newProject.files, ...e.target.files] })} />
                            <div>
                                <Label>Selected file(s)</Label>
                                {_.map(newProject.files, (f) => {
                                    return (
                                        <div className='file-upload-wrapper'>
                                            <p>{f.name}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <Button className='reset-upload-btn' type='reset' onClick={() => removeFiles()}>Clear uploads</Button>
                        </Row>
                    </Form>
                </div>
            )
        }
        if (steps === 3) {
            return (
                <div>
                    <Row className='mt-2'>
                        <Label>Invite users</Label>
                        <div className='select-btn-wrapper'>
                            <div>
                                <Button className='selec-all' onClick={() => inviteAll()}>Select all</Button>
                            </div>
                            <div>
                                <Button className='selec-all' onClick={() => setInvitedUsers([])}>Remove all</Button>
                            </div>
                            <div>
                                <UncontrolledButtonDropdown className='selec-all'  >
                                    <DropdownToggle caret>
                                        {specialityFilter}
                                    </DropdownToggle>
                                    <DropdownMenu className='menu-scroll'>
                                        <DropdownItem onClick={() => setSpecialityFilter('All')}>All</DropdownItem>
                                        <DropdownItem divider />
                                        {_.map(_.orderBy(specialityList, [s => s.speciality.toLowerCase()], ['asc']), (s, index) => {
                                            return <DropdownItem key={index} onClick={() => setSpecialityFilter(s.speciality)}>{s.speciality}</DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                        <Col className='invite-users-wrapper'>
                            <Row className='inner-invite-wrapper'>
                                {renderInviteUsers()}
                            </Row>
                        </Col>

                    </Row>


                </div>
            )
        } if (steps === 4) {
            return (
                <div className='uploading-status-wrapper'>
                    <Label className='w-100 text-center mb-2'>Creating project, please do not close this page.</Label>
                    <Spinner color='info' />
                    <p className='w-100 text-center mt-2'>{uploadStatus}</p>
                    {uploadPercentage !== 0 ?
                        <Progress
                            animated={parseInt(uploadPercentage) !== 100}
                            color="success"
                            value={uploadPercentage}
                            className='mt-2 w-100'
                        />
                        : null
                    }
                </div>
            )
        }
    }
    return (
        <Container className='new-project-main-wrapper'>
            <div className='mid-section'>
                {renderSteps()}
            </div>
            <div>
                {error ? <Alert color='danger' className='mt-2'>{error}</Alert> : null}
            </div>
            <div className='create-btn-wrapper back'>
                <Button color='success' className='create-btn bottom' disabled={steps === 4} onClick={() => changePage('back')}>Back</Button>
                <Button color='success' className='create-btn bottom' disabled={steps === 4} onClick={() => changePage('foward')}>{renderBtnStatus()}</Button>
            </div>
            {customEmailModal()}
        </Container>
    )
}