import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Input, Alert } from 'reactstrap';
import _ from 'lodash';
import './style.css'
import Axios from 'axios';


export const Settings = () => {
    const [specialityList, setSpecialityList] = useState([]);
    const [newSpeciality, setNewSpeciality] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchSpecials()
    }, [])

    const fetchSpecials = async () => {
        const results = await Axios.get('/api/speciality', { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        if (results.status === 200) {
            setSpecialityList([...results.data])
        }
    }

    const addSpeciality = async () => {
        setError(null);
        let exists = false;
        _.each(specialityList, (s) => {
            if (s.speciality === newSpeciality.toLowerCase()) {
                exists = true;
            }
        })
        if (!exists) {
            await Axios.post('/api/speciality', { speciality: newSpeciality.toLowerCase() }, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
            fetchSpecials();
            setNewSpeciality('')
        } else {
            setError('Speciality already exists.')
        }
    }

    const deleteSpecial = async (speciality) => {
        await Axios.post('/api/speciality/delete', { ...speciality }, 
        { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
            fetchSpecials();
    }

    const renderSpecials = () => {
        return _.map(_.orderBy(specialityList, [s => s.speciality.toLowerCase()], ['asc']), (s, index) => {
            return (
                <div key={index} className='speciality-wrapper'>
                    <div className='speciality'>
                        <p>{s.speciality}</p>
                    </div>
                    <div className='speciality-delete'>
                        <p onClick={()=> deleteSpecial(s)}>Delete</p>
                    </div>
                </div>
            )
        })
    }

    return (
        <Container className='dashboard-main-wrapper'>
            <Row >
                <Col md={5}>
                    <Row>
                        <Col>
                            <Input placeholder='type speciality' value={newSpeciality} onChange={(e) => setNewSpeciality(e.target.value)} />
                            {error ? <Alert color='danger'>{error}</Alert> : null}
                        </Col>
                        <Col>
                            <Button className='mt-3' onClick={() => addSpeciality()}>ADD</Button>
                        </Col>
                    </Row>
                </Col>
                <Col className='speciality-main-wrapper'>
                    {renderSpecials()}
                </Col>
            </Row>

        </Container>
    )
}