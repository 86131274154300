import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { FaEdit, FaTrash } from 'react-icons/fa'
import _, { filter } from 'lodash';
import './style.css';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { SubmissionRow } from './components/submissions';

import classnames from 'classnames';
import moment from 'moment'

export const ProjectList = () => {
    const history = useHistory();
    const [projectList, setProjectList] = useState([]);
    const [nonActiveList, setNonActiveList] = useState([]);
    const [projectToDelete, setProjectToDelete] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const columns = [
        {
            name: 'Project name',
            selector: 'projectName',
        },
        {
            name: 'Start Date',
            sortable: true,
            cell: row => {
                return moment(row.startDate).format('DD/MM/YY')
            },

        },
        {
            name: 'End Date',
            sortable: true,
            cell: row => {
                return moment(row.endDate).format('DD/MM/YY')
            },

        },
        {
            name: 'Submissions received',
            sortable: false,
            cell: row => {
                return _.size(row.submissions)
            },

        },
        {
            name: 'Awarded to',
            sortable: true,
            cell: row => {
                return !_.isEmpty(row.awardedTo) ? row.awardedTo : null;
            },
        },
        {
            name: 'Active',
            sortable: true,
            cell: row => {
                return row.isActive ? 'Yes' : 'No';
            },
        },
        {
            name: '',
            sortable: false,
            cell: row => {
                return renderEditBtn(row)
            },

        },
    ];

    const fetchProjects = async () => {
        const results = await Axios.get('/api/projects/all', { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            let active = [...filter(results.data, (d) => {
                return d.isActive === true;
            })]
            let notActive = [...filter(results.data, (d) => {
                return !d.isActive;
            })]
            setProjectList([...active]);
            setNonActiveList([...notActive])
        } else {
            console.log('Failed to fetch projects');
        }
    }

    useEffect(() => {
        fetchProjects()
    }, []);

    const viewProject = (project) => {
        history.push(`/tender/view/${project._id}`)
    }

    const confirmDeleteProject = () => {
        if (!projectToDelete) return null;
        return (
            <Modal isOpen={showConfirmDelete} toggle={() => setShowConfirmDelete(!showConfirmDelete)} className='modal-class'>
                <ModalHeader><h4>Confirm deletion</h4></ModalHeader>
                <ModalBody>
                    <p>Please confirm you wish to delete <strong>{projectToDelete.projectName}</strong>. It will be deleted instantly along with files and submissions.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => deleteProject(projectToDelete)}>Delete</Button>
                    <Button onClick={() => setShowConfirmDelete(!showConfirmDelete)}>No, Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    const deleteProject = async (project) => {
        const results = await Axios.post(`/api/projects/delete`, { ...project }, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        fetchProjects();
        setShowConfirmDelete(!showConfirmDelete);
    }


    const renderEditBtn = (project) => {
        return (
            <div className='edit-btn-wrapper'>
                <FaEdit size={25} className='edit-icons' onClick={() => viewProject(project)} />
                <FaTrash color={'red'} size={25} className='edit-icons' onClick={() => {
                    setProjectToDelete(project);
                    setShowConfirmDelete(true)
                }} />
            </div>
        )
    }


    const SubHeader = () => {
        return (
            <Container>
                <div className='create-btn-wrapper'>
                    <Button color='primary' className='create-btn' onClick={() => history.push('/projects/new')}>Create new project</Button>
                </div>
            </Container>
        )
    }

    const Submissions = ({ data }) => {
        let rows = _.map(data.submissions, (s, index) => {
            return <SubmissionRow data={s} project={data} fetchProjects={fetchProjects} key={index} />
        });
        rows = _.sortBy(rows, ['awardedTo']).reverse();
        return (
            <div>
                <Label className='text-center w-100 mt-2'>Project Submissions</Label>
                {_.isEmpty(rows) ? <p className='mt-2 mb-2 text-center w-100'>None yet!</p> : rows}
            </div>
        )
    }

    return (
        <Container className='project-main-wrapper'>
            {SubHeader()}
            <Nav light tabs >
                <NavItem>
                    <NavLink
                        className={classnames({ active: currentTab === 1 })}
                        onClick={() => setCurrentTab(1)}
                    >
                        Active Projects
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: currentTab === 2 })}
                        onClick={() => setCurrentTab(2)}
                    >
                        In-Active Projects (Awarded)
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={currentTab}>
                <TabPane tabId={1}>
                    <DataTable
                        title="Project List (Active)"
                        columns={columns}
                        data={projectList}
                        highlightOnHover={true}
                        pagination={true}
                        paginationPerPage={10}
                        expandableRows={true}
                        expandableRowsComponent={<Submissions />}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    />
                </TabPane>
                <TabPane tabId={2}>
                <DataTable
                        title="Project List (Non-active)"
                        columns={columns}
                        data={nonActiveList}
                        highlightOnHover={true}
                        pagination={true}
                        paginationPerPage={10}
                        expandableRows={true}
                        expandableRowsComponent={<Submissions />}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    />
                </TabPane>
            </TabContent>

            {confirmDeleteProject()}
        </Container>
    )
}