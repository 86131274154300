import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Login } from '../login';
import { Nav } from '../nav';
import './style.css'
import { useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from '../errorwrapper';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

export const AuthWrapper = ({ Component }) => {

    const [token, setToken] = useState()
    const history = useHistory();
    useEffect(() => {
        const token = localStorage.getItem('token');
        setToken(token)
    }, [])

    const isTokenValid = () => {
        const decoded = jwt_decode(token);
        localStorage.setItem('username', decoded.username);
        localStorage.setItem('expires', decoded.expires);
        localStorage.setItem('id', decoded.id);
        localStorage.setItem('userType', decoded.userType);
        const now = moment().unix();
        if(decoded.expires < now) {
            localStorage.removeItem(token);
            history.push('/')
            return false;
        }
        return true;
    }

    const renderHeaderType = () => {
        const type = localStorage.getItem('userType')
        if (type === 'globalAdmin' || type === 'admin') {
            return <p className='account-type'>Administrator</p>
        } else {
            return <p className='account-type'>Contractor</p>
        }
    }

    const logout = () => {
        localStorage.removeItem(token);
        history.push('/')
    }

    if (token && isTokenValid()) {
        return (
            <Container fluid={true}>
                <Row className='main-row'>
                    <ToastContainer />
                    <Col xl={2} lg={2} md={2} sm={3} className='nav-col'>
                        <Nav />
                    </Col>
                    <Col className='page-col'>
                        <ErrorBoundary>
                            <Container className='header'>
                                <div className='welcome-wrapper'>
                                    <p>Welcome {localStorage.getItem('username')}</p>
                                    {renderHeaderType()}
                                </div>
                                <p style={{ cursor: 'pointer' }} onClick={()=>logout()}><strong>Logout</strong></p>
                            </Container>
                            <Component />
                        </ErrorBoundary>
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return <Login />
    }


}
