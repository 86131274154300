import React, { useState, useEffect } from 'react';
import {
    Container, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Input, Label, Row, Col,
} from 'reactstrap';
import _ from 'lodash';
import './style.css'
import Axios from 'axios';
import DataTable from 'react-data-table-component';
import Toggle from 'react-toggle';
import { toast } from 'react-toastify';
import { FaUserEdit, FaUserTimes, FaUserLock } from 'react-icons/fa'
import { NewUser } from './components/new-user-modal';




export const Users = () => {
    const [users, setUsers] = useState([]);
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const columns = [
        {
            name: 'Display',
            selector: 'displayName',
            sortable: true,
        },
        {
            name: 'Username',
            selector: 'username',
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: false,
        },
        {
            name: 'Speciality',
            selector: 'speciality',
            sortable: true,
        },
        {
            name: 'Account Type',
            cell: row => {
                return renameUserType(row.userType)
            }
        },
        {
            name: 'Active',
            cell: row => {
                return renderActiveToggle(row)
            },
            sortable: false,
        },
        {
            name: '',
            cell: row => {
                return renderEditBtn(row)
            },
            sortable: false,
        },
    ];
    const [isOpen, setIsOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [passwordValid, setPasswordValid] = useState(false);
    const [specialityList, setSpecialityList] = useState(false);

    const fetchUsers = async () => {
        const results = await Axios.get('/api/users/all', { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            setUsers([...results.data]);
        } else {
            console.log('Failed to fetch users');
        }
    }

    const fetchSpecials = async () => {
        const results = await Axios.get('/api/speciality', { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            setSpecialityList([...results.data]);
        } else {
            console.log('Failed to fetch specials');
        }
    }

    useEffect(() => {
        fetchUsers()
        fetchSpecials()
    }, [])

    const updateUserToggle = async (user) => {
        let userObj = {
            ...user,
            isActive: !user.isActive
        }
        Axios.put(`/api/users/update`, { ...userObj }, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
            .then(() => {
                toast.success('User updated!', {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                fetchUsers()
            })
            .catch((ex) => {
                console.log(ex);
                toast.error('Failed to update user!', {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
    }

    const renameUserType = (userType) => {
        if (userType === 'globalAdmin') {
            return 'Global Admin'
        } else if (userType === 'admin') {
            return 'Admin'
        } else {
            return 'Contractor'
        }
    }

    const renderActiveToggle = (user) => {
        if (user.userType === 'globalAdmin') return null;
        return (
            <div>
                <Toggle
                    defaultChecked={user.isActive}
                    onChange={() => updateUserToggle(user)} />
            </div>
        )
    }

    const confirmDelete = () => {
        if (!userToDelete) return null
        const { username } = userToDelete;
        return (
            <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className='modal-class'>
                <ModalHeader><h4>Confirm deletion</h4></ModalHeader>
                <ModalBody>
                    <p>Please confirm you wish to delete <strong>{username}</strong></p>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => deleteUser(userToDelete)}>Delete</Button>
                    <Button onClick={() => setIsOpen(!isOpen)}>No, Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    const deleteUser = async (user) => {
        const results = await Axios.post('/api/users/delete', { ...user }, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            toast.success('User deleted!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            fetchUsers();
            setIsOpen(!isOpen)
        } else {
            toast.error('Failed to delete user', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const renderEditBtn = (user) => {
        if (user.userType === 'globalAdmin') return null;
        return (
            <div className='edit-btn-wrapper'>         
                <FaUserEdit size={25} id={`${user.username}-edit`} className='edit-icons' onClick={() => {
                    setSelectedUser(user);
                    setShowEditModal(!showEditModal);
                }} />
                <FaUserTimes size={25} className='edit-icons warning' onClick={() => {
                    setUserToDelete(user)
                    setIsOpen(true)
                }} />
                <FaUserLock size={25} className='edit-icons' onClick={() => {
                    setSelectedUser(user)
                    setShowPasswordModal(true)
                }} />
            </div>
        )
    }


    const SubHeader = () => {
        return (
            <Container>
                <div className='create-btn-wrapper'>
                    <Button color='primary' className='create-btn' onClick={() => setShowNewUserModal(!showNewUserModal)}>Create new user</Button>
                </div>
            </Container>
        )
    }

    const updateUser = async () => {
        const results = await Axios.put('/api/users/update', { ...selectedUser }, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            toast.success('User updated!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            fetchUsers();
            setShowEditModal(!showEditModal)
            setSelectedUser(null);
        } else {
            toast.error('Failed to change user password', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const renderSpeciality = () => {
        return _.map(_.sortBy(specialityList, ['speciality']), (s, index) => {
            return <option key={index} value={s.speciality}>{s.speciality}</option>
        })
    }

    const editUserModal = () => {
        return (
            <Modal isOpen={showEditModal} toggle={() => setShowEditModal(!showEditModal)} className='modal-class'>
                <ModalHeader><h4>Update user</h4></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Label>Username</Label>
                            <Input value={selectedUser.username} type='text' onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })} />
                        </Col>
                        <Col>
                            <Label>Email</Label>
                            <Input value={selectedUser.email} type='email' onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Display name</Label>
                            <Input value={selectedUser.displayName} type='text' onChange={(e) => setSelectedUser({ ...selectedUser, displayName: e.target.value })} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>User type</Label>
                            <Input value={selectedUser.userType} type='select' onChange={(e) => setSelectedUser({ ...selectedUser, userType: e.target.value })}>
                                <option value='contractor'>Contractor</option>
                                <option value='admin'>Admin</option>
                            </Input>
                        </Col>
                    </Row>
                    {
                        selectedUser.userType === 'contractor' ?
                            <Row>
                                <Col>
                                    <Label>User Speciality</Label>
                                    <Input type='select' value={selectedUser.speciality} onChange={(e) => setSelectedUser({ ...selectedUser, speciality: e.target.value })}>
                                        <option value='-'>Select an option</option>
                                        {renderSpeciality()}
                                    </Input>
                                </Col>
                            </Row>
                            : null
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={() => updateUser()}>Update</Button>
                    <Button onClick={() => setShowEditModal(!showEditModal)}>No, Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    const passwordCheck = () => {
        const { password, confPassword } = selectedUser;
        if (password === confPassword && !_.isEmpty(password)) {
            return true
        }
    }
    const updatePassword = async () => {
        const { password, confPassword } = selectedUser;
        if (password === confPassword) {
            const results = await Axios.put('/api/users/password', { ...selectedUser }, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
            if (results.status === 200) {
                toast.success('User password changed!', {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setShowPasswordModal(!showPasswordModal)
                setSelectedUser(null);
            } else {
                toast.error('Failed to change user password', {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    const passwordResetModal = () => {
        return (
            <Modal isOpen={showPasswordModal} toggle={() => setShowPasswordModal(!showPasswordModal)} className='modal-class'>
                <ModalHeader><h4>Update Password</h4></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Label>Password</Label>
                            <Input type='password' valid={passwordCheck()} invalid={!passwordCheck()} onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })} />
                        </Col>
                        <Col>
                            <Label>Confirm password</Label>
                            <Input type='password' valid={passwordCheck()} invalid={!passwordCheck()} onChange={(e) => setSelectedUser({ ...selectedUser, confPassword: e.target.value })} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={() => updatePassword()}>Update</Button>
                    <Button onClick={() => setShowPasswordModal(!showPasswordModal)}>No, Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    return (
        <Container className='users-main-wrapper'>
            {SubHeader()}
            <DataTable
                title="User List"
                columns={columns}
                data={users}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            />
            {NewUser(showNewUserModal, setShowNewUserModal, fetchUsers, specialityList)}
            {confirmDelete()}
            {selectedUser ? editUserModal() : null}
            {selectedUser ? passwordResetModal() : null}
        </Container>
    )
}