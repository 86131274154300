


export const userReducer = (state = { token: null }, action) => {
    switch (action.type) {
      case 'SET':
        return { token: action.payload }
      case 'DELETE':
        return { token: null }
      default:
        return state
    }
  }