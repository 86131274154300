import React, { useState, useEffect } from 'react';
import { Container, Input, Button, Spinner, Alert, FormText } from 'reactstrap';
import _ from 'lodash';
import './style.css';
import { ForgottenPasswordModal } from './components/forgottenPw';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
export const Login = () => {

    const [credentials, setCredentials] = useState({ username: '', password: '' })
    const [loginError, setLoginError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const history = useHistory();

    const login = () => {
        setLoginError(null);
        setLoading(true);
        if (isValid('username') && isValid('password')) {
            //login
            Axios.post('/api/login', {username: credentials.username.toLowerCase(), password: credentials.password})
            .then((result)=>{
                localStorage.setItem('token', result.data.token)
                history.push('/dashboard')
            })
            .catch((err)=>{
                console.log(err)
                setLoginError('Username or password incorrect.');
            });
            setLoading(false)
        } else {
            setLoginError('Please enter your username & password.');
            setLoading(false);
        }
    }

    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            login();
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      },);

    const isValid = (locationCheck) => {
        if (!_.isEmpty(credentials[locationCheck])) {
            return true
        } else {
            return false
        }
    }

    return (
        <Container fluid={true} className='login-main-wrapper'>
            <Container className='inner-login-wrapper'>
                <div className='title-wrapper'>
                    <h1>Tenders Portal</h1>
                </div>
                <Input placeholder='Username' valid={isValid('username')} onChange={(e) => setCredentials({ ...credentials, username: e.target.value })} />
                <Input placeholder='Password' type='password' valid={isValid('password')} onChange={(e) => setCredentials({ ...credentials, password: e.target.value })} />
                <div className='login-btn-wrapper'>
                    <Button color='primary' onClick={() => login()}>{loading ? <Spinner color="light"  /> : 'Login'}</Button>
                </div>
                <div className='error-wrapper'>
                    {loginError ? <Alert color='danger'>{loginError}</Alert> : null}
                </div>
                <div className='error-wrapper'>
                    <FormText className='white-text'>Forgotten password? Please contact Sam Millar</FormText>
                    
                </div>
                <div className='error-wrapper mt-1'>
                <FormText className='white-text text-center'>ph: 027 464 1042</FormText>
                </div>

            </Container>
        </Container>
    )
}