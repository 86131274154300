import React, { useState, useEffect } from 'react';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import _ from 'lodash';
import './style.css'
import { ProjectItem } from './components/projectListItem';
import Axios from 'axios';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';


export const Dashboard = () => {
    const [tenders, setTenders] = useState([]);
    const [tendersInActive, setTendersInActive] = useState([]);
    const [awardedTenders, setAwardedTenders] = useState([]);
    const [currentTab, setCurrentTab] = useState(1);
    const history = useHistory();

    const fetchTenders = async () => {
        const results = await Axios.get('/api/projects/invited', { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            let inactive = [..._.filter(results.data, (d) => {
                return d.isActive === false && d.awardedTo !== localStorage.getItem('username');
            })]
            let awardedTenders = [..._.filter(results.data, (d) => {
                return d.awardedTo === localStorage.getItem('username');
            })]
            let tenders = [..._.filter(results.data, (d) => {
                return d.isActive;
            })]
            setAwardedTenders([...awardedTenders])
            setTendersInActive([...inactive])
            setTenders([...tenders]);
        } else {
            console.log('Failed to fetch tenders');
        }
    }

    useEffect(() => {
        fetchTenders();
    }, []);

    if(localStorage.getItem('userType') === 'admin' || localStorage.getItem('userType') === 'globalAdmin') {
        history.push('/projects')
    }

    return (
        <Container className='dashboard-main-wrapper'>
            <h2>Tenders</h2>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: currentTab === 1 })}
                        onClick={() => setCurrentTab(1)}
                    >
                        Current Tenders
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: currentTab === 2 })}
                        onClick={() => setCurrentTab(2)}
                    >
                        Awarded Tenders
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: currentTab === 3 })}
                        onClick={() => setCurrentTab(3)}
                    >
                        Inactive Tenders
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={currentTab}>
                <TabPane tabId={1}>
                    {!_.isEmpty(tenders) ? _.map(tenders, (data) => ProjectItem(data)) : null}
                </TabPane>
                <TabPane tabId={2}>
                    {!_.isEmpty(awardedTenders) ? _.map(awardedTenders, (data) => ProjectItem(data)) : null}
                </TabPane>
                <TabPane tabId={3}>
                    {!_.isEmpty(tendersInActive) ? _.map(tendersInActive, (data) => ProjectItem(data)) : null}
                </TabPane>
            </TabContent>

        </Container>
    )
}