import React from 'react';
import _ from 'lodash';
import './style.css'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.

        return { hasError: true, };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // console.log(error)
        // console.log(errorInfo)
        this.setState({
            ...this.state,
            error: error,
            errorInfo: errorInfo
        })
        //logErrorToMyService(error, errorInfo);
    }



    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            //console.log(this.state.error)
            return (
                <div className='error-wrapper'>
                    <div className='card-body'>
                        <h1>Something went wrong.</h1>
                        {this.state.error ? <RenderError error={this.state.error} errorInfo={this.state.errorInfo.componentStack} /> : null}
                    </div>
                </div>


            );
        }

        return this.props.children;
    }
}

const RenderError = ({ error, errorInfo }) => {
    return (
        <div >
            <div className='error-block'>
                <code>
                    <p>{error.toString()}</p>
                    {errorInfo.toString()}
                </code>
            </div>
            <div className='text-center mt-2'>
                <p>Please email support@reliance.net.nz with the above error log.</p>
            </div>
        </div>


    )
}