import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import { FaColumns, FaUser, FaTasks, FaCogs } from 'react-icons/fa';
import { useHistory } from 'react-router';
import './style.css';
import { useDispatch } from 'react-redux';


export const Nav = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const navigate = (nav) => {
        history.push(nav)
    }

    const renderAdminMenus = () => {
        const useType = localStorage.getItem('userType');
        if (useType === 'admin' || useType === 'globalAdmin') {
            return (
                <div>
                    <div>
                        <div className='icon-wrapper' onClick={() => navigate('/projects')}>
                            <FaTasks className='nav-icon' />
                            <p>Projects</p>
                        </div>
                    </div>
                    <div>
                        <div className='icon-wrapper' onClick={() => navigate('/users')}>
                            <FaUser className='nav-icon' />
                            <p>Users</p>
                        </div>
                    </div>
                    <div>
                        <div className='icon-wrapper' onClick={() => navigate('/settings')}>
                            <FaCogs className='nav-icon' />
                            <p>Settings</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='icon-wrapper' onClick={() => navigate('/dashboard')}>
                    <FaColumns className='nav-icon' />
                    <p>Dashboard</p>
                </div>
            )
        }
    }

    return (
        <Container className='main-nav-wrapper'>
            <div className='nav-wrapper'>
                {renderAdminMenus()}
            </div>
        </Container>
    )
}