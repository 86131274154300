import React, { useState, useEffect } from 'react';
import { Container, Spinner, Button, Input, Row, Col, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap';
import _ from 'lodash';
import './style.css';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { SubmissionBlock } from './components/submission';
const fileDownload = require('js-file-download');


export const ViewProject = () => {
    const [sending, setSending] = useState(false);
    const [newEmail, setNewEmail] = useState({emailText: '', projectId: null, userId: null, sentTo: null})
    const [sendEmailModal, setSendEmailModal] = useState(false);
    const [showEmailHistory, setShowEmailHistory] = useState(false);
    const [emailHistory, setEmailHistory] = useState([])
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [openProject, setOpenProject] = useState(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [projectUpdatingModal, setProjectUpdatingModal] = useState(false);
    const [updateStatus, setUpdateStatus] = useState('');
    const [fileToDelete, setFileToDelete] = useState(null)
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [fileDownloading, setFileDownloading] = useState(false);
    const [readOnly, setReadOnly] = useState(localStorage.getItem('userType') === 'contractor' ? true : false);

    const fetchUsers = async () => {
        const results = await Axios.get('/api/users/all', { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            let data = [..._.filter(results.data, (u) => {
                return u.userType !== 'admin' && u.userType !== 'globalAdmin';
            })]
            setUsers([...data]);
        } else {
            console.log('Failed to fetch users');
        }
    }

    const fetchProject = async () => {
        const results = await Axios.get(`/api/projects?id=${params.id}`, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            setOpenProject({ ...results.data })
            // await Promise.all(_.map(results.data))
            // loop for submissions
            // setProjectList([...results.data]);
            let viewedBy = [...results.data.viewedBy];
            if (!_.includes(viewedBy, localStorage.getItem('id')) && readOnly) {
                viewedBy.push(localStorage.getItem('id'))
                const update = await Axios.put(`/api/projects/update`, { ...results.data, viewedBy: [...viewedBy] },
                    { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
            }
            setLoading(false)
        } else {
            console.log('Failed to fetch users');
        }
    }

    useEffect(() => {
        fetchProject()
        fetchUsers()
    }, []);

    const updateSearch = () => {
        const inputValue = search.trim().toLowerCase();
        const inputLength = inputValue.length;
        const reg = new RegExp(inputValue + '.*', 'i');
        let filteredData = inputLength === 0 ? [] : _.filter(users, (details) => {
            return details.displayName.toLowerCase().search(reg) !== -1
        })
        setSearchResults(filteredData)
    }

    useEffect(() => {
        updateSearch()
    }, [search])

    const downloadFile = async (file) => {
        if (fileDownloading) return;
        setFileDownloading(true)
        const results = await Axios.get(`/api/projects/file?id=${file.id}&filename=${file.fileName}`, { responseType: 'blob', headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        fileDownload(results.data, `${file.fileName}`);
        setFileDownloading(false)
    };

    const deleteFile = async (file) => {
        const results = await Axios.post(`/api/projects/file/delete`, { fileId: file.id, projectId: params.id }, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        fetchProject();
        setConfirmDeleteModal(!confirmDeleteModal);
    }

    const confirmDelete = () => {
        if (!fileToDelete) return null;
        return (
            <Modal isOpen={confirmDeleteModal} toggle={() => setConfirmDeleteModal(!confirmDeleteModal)} className='modal-class'>
                <ModalHeader><h4>Confirm deletion</h4></ModalHeader>
                <ModalBody>
                    <p>Please confirm you wish to delete <strong>{fileToDelete.fileName}</strong>. It will be deleted instantly.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => deleteFile(fileToDelete)}>Delete</Button>
                    <Button onClick={() => setConfirmDeleteModal(!confirmDeleteModal)}>No, Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    const renderFiles = () => {
        return (
            _.map(openProject.files, (file, index) => {
                return (
                    <Row key={index}>
                        <Col>
                            <p className='file-download' onClick={() => downloadFile(file)}>{fileDownloading ? <Spinner color='info' /> : file.fileName}</p>
                        </Col>
                        {readOnly ? null : <Col>
                            <p className='file-delete' onClick={() => {
                                setFileToDelete(file)
                                setConfirmDeleteModal(!confirmDeleteModal)
                            }}>Delete</p>
                        </Col>
                        }
                    </Row>
                )
            })
        )
    }

    const removeInvitedUsers = (user) => {
        let invUsers = [...openProject.invitedUsers];
        invUsers = [..._.filter(invUsers, (u) => {
            return u._id !== user._id;
        })]
        setOpenProject({ ...openProject, invitedUsers: [...invUsers] })
    }



    const renderInvitedUsers = () => {
        return (
            _.map(openProject.invitedUsers, (user, index) => {
                return (
                    <Row key={index}>
                        <Col>
                            <p className='invited-user'>{user.displayName} - {user.speciality}</p>
                        </Col>
                        <Col>
                            <p className='email-history' onClick={() => viewEmailHistory(params.id, user._id)}>View Email History</p>
                        </Col>
                        <Col>
                            <p className='email-send' onClick={()=>setSendEmailInfo(user, params.id)}>Send Email</p>
                        </Col>
                        <Col>
                            <p className='delete-user' onClick={() => removeInvitedUsers(user)}>Delete</p>
                        </Col>
                    </Row>
                )
            })
        )
    }

    const addUser = (user) => {
        let invUsers = [...openProject.invitedUsers];
        let contains = false;
        _.each(invUsers, (u) => {
            if (u.username === user.username) {
                contains = true;
            }
        });
        if (!contains) {
            invUsers.push(user)
        }
        setOpenProject({ ...openProject, invitedUsers: [...invUsers] });
        setSearch('')
    }

    const uploadProgress = (progress) => {
        const { total, loaded } = progress;
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
        setUploadPercentage(uploadPercentage.toFixed(2));
    }

    const updateProject = async () => {
        setUpdateStatus('Updating project details....');
        await Axios.put(`/api/projects/update`, { ...openProject },
            { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        if (fileToUpload) {
            setUpdateStatus('Uploading files....');
            const formData = new FormData();
            _.map(fileToUpload, (f) => {
                formData.append("file", f);
            })
            formData.append("id", openProject._id);
            await Axios.post(`/api/projects/file/create`, formData,
                {
                    headers: {
                        Authorization: `bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: progressEvent => uploadProgress(progressEvent)
                });
        };
        setFileToUpload(null);
        setUpdateStatus('Complete reloading data...');
        fetchProject();
        setUploadPercentage(0);
        setTimeout(() => {
            setProjectUpdatingModal(false);
        }, 3000)

    }

    const viewEmailHistory = async (projectId, userId) => {
        const result = await Axios.get(`/api/projects/emailhistory?projectId=${projectId}&userId=${userId}`,
            { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        if (result.status === 200) {
            setEmailHistory([...result.data])
        }
        setShowEmailHistory(!showEmailHistory)
    }

    const emailHistoryModal = () => {
        return (
            <Modal size={'lg'} isOpen={showEmailHistory} toggle={() => setShowEmailHistory(!showEmailHistory)} className='modal-class'>
                <ModalHeader>Email History</ModalHeader>
                <ModalBody className='email-history-modal-body'>
                    {_.map(emailHistory, email => {
                        return (
                            <Container className='email-history-wrapper'>
                                <Row>
                                    <Col>
                                        Sent To:
                                    </Col>
                                    <Col>
                                        <p>{email.sentTo}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Sent At:
                                    </Col>
                                    <Col>
                                        <p>{email.createdAt}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label>Message</Label>
                                        <Input style={{height: 150}} type='textarea' value={email.emailText} disabled={true} />
                                    </Col>
                                </Row>
                            </Container>
                        )
                    })}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={()=>setShowEmailHistory(!showEmailHistory)}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }

    const setSendEmailInfo = (user, projectId) =>{ 
        setNewEmail({emailText: '', userId: user._id, projectId: projectId, sentTo: user.email, displayName: user.displayName});
        setSendEmailModal(!sendEmailModal)
    }

    const sendMail = async () => {
        setSending(true);
        await Axios.post(`/api/projects/sendemail`, newEmail, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        setSending(false);
        setSendEmailModal(!sendEmailModal)
        toast.success('Mail Sent!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const renderSendEmailModal = () => {
            return (
                <Modal size={'lg'} isOpen={sendEmailModal} toggle={()=>setSendEmailModal(!sendEmailModal)} className='modal-class'>
                    <ModalHeader>Send new mail</ModalHeader>
                    <ModalBody className='email-history-modal-body'>
                    <Input type='textarea' disabled={true} value={`Hi ${newEmail.displayName},`} />
                    <Input style={{height: 150}} type='textarea' onChange={(e)=> setNewEmail({...newEmail, emailText: e.target.value})} value={newEmail.emailText}/>
                    <Input style={{height: 100}} type='textarea' disabled={true} value={'Many Thanks\nDes Millar Construction\nhttps://tenders.desmillar.co.nz'} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' disabled={sending} onClick={()=>sendMail()}>Send</Button>
                        <Button disabled={sending} onClick={()=>setSendEmailModal(!sendEmailModal)}>Close</Button>
                    </ModalFooter>
                </Modal>
            )
    }

    const updateProjectModal = () => {
        return (
            <Modal isOpen={projectUpdatingModal} className='modal-class'>
                <ModalHeader><h4>Updating Project</h4></ModalHeader>
                <ModalBody>
                    <h5 className='text-center w-100'>Please do note refresh or close the page.</h5>
                    <div className='center-loading'>
                        <Spinner />
                        <p className='text-center w-100'>{updateStatus}</p>
                        {uploadPercentage !== 0 ?
                            <Progress
                                striped
                                animated={parseInt(uploadPercentage) !== 100}
                                color="success"
                                value={uploadPercentage}
                                className='mt-2 w-100'
                            />
                            : null
                        }
                    </div>

                </ModalBody>
            </Modal>
        )
    }

    if (loading) return (
        <Container className='project-main-wrapper'>
            <Spinner />
        </Container>
    )

    return (
        <Container className='view-main-wrapper'>
            <Row>
                <Col>
                    <Label>Project name</Label>
                    <Input value={openProject.projectName} onChange={(e) => setOpenProject({ ...openProject, projectName: e.target.value })} disabled={readOnly} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Start date</Label>
                    <Input type='date' value={openProject.startDate}
                        onChange={(e) => setOpenProject({ ...openProject, startDate: e.target.value })} disabled={readOnly} />
                </Col>
                <Col>
                    <Label>End date</Label>
                    <Input type='date' value={openProject.endDate}
                        onChange={(e) => setOpenProject({ ...openProject, endDate: e.target.value })} disabled={readOnly} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Notes</Label>
                    <Input type='textarea' value={openProject.notes} onChange={(e) => setOpenProject({ ...openProject, notes: e.target.value })} disabled={readOnly} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Files</Label>
                    <div>
                        <div className='files-wrapper'>
                            {renderFiles()}
                        </div>
                        {readOnly ? null : <Form className='add-file-wrapper'>
                            <div className='upload-div'>
                                <Label>Add file</Label>
                            </div>
                            <div className='upload-div'>
                                <Input type='file' onChange={(e) => setFileToUpload([...e.target.files])} />
                            </div>
                            <div className='upload-div'>
                                <Button type='reset' className='clear-btn' onClick={() => setFileToUpload(null)}>Clear</Button>
                            </div>
                        </Form>
                        }
                    </div>

                </Col>
            </Row>
            {
                readOnly ? <SubmissionBlock openProject={openProject} fetchProject={fetchProject} /> : null
            }
            {readOnly ? null : <Row>
                <Col>
                    <Label>Add user</Label>
                    <Input type='text' onChange={(e) => setSearch(e.target.value)} value={search} />
                    {
                        !_.isEmpty(searchResults) ?
                            <div className='user-search'>
                                {_.map(searchResults, (r) => {
                                    return <p onClick={() => addUser(r)}>{r.displayName} - {r.speciality}</p>
                                })}
                            </div> :
                            null
                    }
                    <Label className='mb-3'>Invited users</Label>
                    <div className='invited-users-main-wrapper'>
                        {renderInvitedUsers()}
                    </div>
                </Col>
            </Row>
            }
            {readOnly ? null : <Row>
                <Col>
                    <div className='end-btn-wrapper'>
                        <Button color='success' onClick={() => {
                            setProjectUpdatingModal(true)
                            updateProject()
                        }}>Update</Button>
                    </div>
                </Col>
            </Row>
            }
            {confirmDelete()}
            {updateProjectModal()}
            {emailHistoryModal()}
            {renderSendEmailModal()}
        </Container>
    )
}