import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Row, Col } from 'reactstrap';
import _ from 'lodash';
import Axios from 'axios';

import { toast } from 'react-toastify';



export const NewUser = (isOpen, closeFunc, fetchUsers, speciality) => {

    const [newUser, setNewUser] = useState({
        username: '', password: '', confPassword: '',
        userType: 'contractor', isActive: true, email: '',
        speciality: '-', displayName: ''
    });

    const isValid = (field) => {
        const { username, password, confPassword, userType,
            email, speciality, displayName } = newUser;
        if (field === 'username') {
            if (!_.isEmpty(username)) {
                return true;
            } else {
                return false;
            }
        }
        if (field === 'displayName') {
            if (!_.isEmpty(displayName)) {
                return true;
            } else {
                return false;
            }
        }
        if (field === 'password') {
            if (!_.isEmpty(password) && password === confPassword) {
                return true;
            } else {
                return false;
            }
        }
        if (field === 'confPassword') {
            if (!_.isEmpty(confPassword) && password === confPassword) {
                return true;
            } else {
                return false;
            }
        }
        if (field === 'email') {
            if (!_.isEmpty(email)) {
                return true;
            } else {
                return false;
            }
        }
        if (field === 'userType') {
            if (!_.isEmpty(userType)) {
                return true;
            } else {
                return false;
            }
        }
        if (field === 'speciality') {
            if (speciality !== '-') {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    const createUser = async () => {
        if (!isValid('username') || !isValid('password') || !isValid('email') || !isValid('displayName')) return;
        Axios.post('/api/users/create', { ...newUser }, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
            .then(results => {
                console.log(results.status)
                toast.success('User created!', {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                fetchUsers();
                setNewUser({
                    username: '', password: '', confPassword: '',
                    userType: 'contractor', isActive: true, email: '',
                    speciality: '-'
                })
                closeFunc(!isOpen)
            })
            .catch((err, x) => {
                if (err.hasOwnProperty('response') && err.response.hasOwnProperty('data')) {
                    toast.error(`Failed to create user. ${err.response.data.error}`, {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: true,
                    });
                }
            })

    }

    const renderSpeciality = () => {
        return _.map(_.orderBy(speciality, [s => s.speciality.toLowerCase()], ['asc']), (s, index) => {
            return <option key={index} value={s.speciality}>{s.speciality}</option>
        })
    }

    return (
        <Modal isOpen={isOpen} toggle={() => closeFunc(!isOpen)} className='modal-class'>
            <ModalHeader>New user</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Label>Username</Label>
                        <Input type='text' valid={isValid('username')} invalid={!isValid('username')} onChange={(e) => setNewUser({ ...newUser, username: e.target.value })} />
                    </Col>
                    <Col>
                        <Label>Email</Label>
                        <Input type='email' valid={isValid('email')} invalid={!isValid('email')} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Display name</Label>
                        <Input type='text' valid={isValid('displayName')} invalid={!isValid('displayName')} onChange={(e) => setNewUser({ ...newUser, displayName: e.target.value })} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Password</Label>
                        <Input type='password' valid={isValid('password')} invalid={!isValid('password')} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Confirm password</Label>
                        <Input type='password' valid={isValid('confPassword')} invalid={!isValid('confPassword')} onChange={(e) => setNewUser({ ...newUser, confPassword: e.target.value })} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>User type</Label>
                        <Input valid={isValid('userType')} type='select' onChange={(e) => setNewUser({ ...newUser, userType: e.target.value })}>
                            <option value='contractor'>Contractor</option>
                            <option value='admin'>Admin</option>
                        </Input>
                    </Col>
                </Row>
                {
                    newUser.userType === 'contractor' ?
                        <Row>
                            <Col>
                                <Label>User Speciality</Label>
                                <Input valid={isValid('speciality')} invalid={!isValid('speciality')} type='select' onChange={(e) => setNewUser({ ...newUser, speciality: e.target.value })}>
                                    <option value='-'>Select an option</option>
                                    {renderSpeciality()}
                                </Input>
                            </Col>
                        </Row>
                        : null
                }
            </ModalBody>
            <ModalFooter>

                <Button color='success' onClick={() => createUser()}>Create</Button>
                <Button onClick={() => closeFunc(!isOpen)}>No, Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}