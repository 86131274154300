import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { userReducer } from './components/reducers/user';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './main.css';
import "react-toggle/style.css";
import 'react-toastify/dist/ReactToastify.css';
import { Login } from './components/login';
import { AuthWrapper } from './components/authwrapper';
import { Dashboard } from './components/dashboard';
import { Users } from './components/users';
import { ProjectList } from './components/projects';
import { NewProject } from './components/projects/components/new-project';
import { ViewProject } from './components/projects/components/view-project';
import { Settings } from './components/settings';

const store = createStore(userReducer)

ReactDOM.render(
 
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path='/dashboard'>
            <AuthWrapper Component={Dashboard} />
          </Route>
          <Route path='/users'>
            <AuthWrapper Component={Users} />
          </Route>
       
          <Route path='/projects/new'>
            <AuthWrapper Component={NewProject} />
          </Route>
          <Route path='/projects'>
            <AuthWrapper Component={ProjectList} />
          </Route>
          <Route path='/tender/view/:id'>
            <AuthWrapper Component={ViewProject} />
          </Route>
          <Route path='/settings'>
            <AuthWrapper Component={Settings} />
          </Route>
          <Route exact path='/' component={Login} />

        </Switch>
      </Router>
    </Provider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
