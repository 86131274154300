import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Row, Col, Spinner } from 'reactstrap';
import _ from 'lodash';
import Axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa';
const fileDownload = require('js-file-download');

export const SubmissionRow = ({ data, project, fetchProjects }) => {
    const [loading, setLoading] = useState(true);
    const [submission, setSubmission] = useState({});
    const [showInfo, setShowInfo] = useState(false);
    const [fileDownloading, setFileDownloading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const downloadFile = async (file) => {
        if (fileDownloading) return;
        setFileDownloading(true)
        const results = await Axios.get(`/api/projects/file?id=${file.id}&filename=${file.fileName}`, { responseType: 'blob', headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        fileDownload(results.data, `${file.fileName}`);
        setFileDownloading(false)
    };


    const fetchSubmission = async () => {
        const results = await Axios.get(`/api/projects/submission?id=${data.submissionId}`, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        if (results.status === 200) {
            setSubmission({ ...results.data });
            setLoading(false)
        } else {
            console.log('Failed to get submission');
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchSubmission()
    }, []);

    const viewSubmission = () => {
        return (
            <Modal isOpen={showInfo} toggle={() => setShowInfo(!showInfo)} className='modal-class' size='lg'>
                <ModalHeader><h4>Submission Information</h4></ModalHeader>
                <ModalBody>
                    <Label className='mb-2'>Notes</Label>
                    <Input type='textarea' style={{ minHeight: 150 }} disabled={true} value={submission.notes} />
                    <Label className='mt-3 mb-2'>Attached File(s)</Label>
                    {
                        _.map(submission.files, (file, index) => {
                            return (
                                <Row key={index}>
                                    <Col>
                                        <p className='file-download' onClick={() => downloadFile(file)}>{fileDownloading ? <Spinner color='info' /> : file.fileName}</p>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color='success' disabled={project.awardedTo} onClick={() => setShowConfirm(!showConfirm)}>Accept</Button>
                    <Button onClick={() => setShowInfo(!showInfo)}>No, Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    const confirmWarning = () => {
        return (
            <Modal isOpen={showConfirm} toggle={() => setShowConfirm(!showConfirm)} className='modal-class'>
                <ModalHeader><h4>Confirm Acceptance</h4></ModalHeader>
                <ModalBody>
                    <p>Please confirm you are awarding this project to <strong>{submission.submitterName}</strong></p>
                    <p>This will deactivate the project immediately.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={() => acceptSubmission()}>Accept</Button>
                    <Button onClick={() => setShowConfirm(!showConfirm)}>No, Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    const acceptSubmission = async () => {
        const update = await Axios.put(`/api/projects/update`, { ...project, awardedTo: submission.submitterName, isActive: false },
            { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } });
        if (update.status === 200) {
            setShowConfirm(false)
            setShowInfo(false)
            fetchProjects();
        }
    }

    if (loading) {
        return (
            <div className='loader pt-4'>
                <Spinner size={25} color='info' />
            </div>
        )
    }

    return (
        <div className='submission-row' style={project.awardedTo === submission.submitterName ? { borderColor: 'green', borderWidth: 2 } : null}>

            <div className='submitter-name'>
                {
                    project.awardedTo === submission.submitterName ?
                        <div >
                            <FaCheckCircle color='green' className='tick-icon' />
                            {submission.submitterName}
                        </div>
                        :
                        submission.submitterName
                }
                
            </div>
            <div>

                <Button color='info' className='submission-row-buttons' onClick={() => setShowInfo(!showInfo)}>View</Button>
            </div>
            {viewSubmission()}
            {confirmWarning()}
        </div>
    )
}