import React, { useState } from 'react';
import { Row, Col, Button, Label } from 'reactstrap';
import _ from 'lodash';
import './style.css'
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const ProjectItem = ({ projectName, startDate, endDate, isActive, viewedBy, submissions, awardedTo, _id }) => {
    const history = useHistory();
    const renderBadge = () => {
        if (!_.includes(viewedBy, localStorage.getItem('id'))) {
            return (
                <div className='projectitem-badge-wrapper-new'>
                    <p>NEW</p>
                </div>
            )
        } else {
            return null;
        }

    }

    const renderStatus = () => {
        if (isActive) {
            return (
                <div className='projectitem-badge-wrapper-active'>
                    <p>Active</p>
                </div>
            )
        } else {
            return (
                <div className='projectitem-badge-wrapper-new'>
                    <p>In-Active</p>
                </div>
            )
        }

    }

    return (
        <Row className='projectitem-row'>
            <Col lg={1} md={1} className='projectitem-col'>
                {renderBadge()}
            </Col>
            <Col className='projectitem-col'>
                <div>
                    <Label>Project Name</Label>
                    <p>{projectName}</p>
                </div>
            </Col>
            <Col className='projectitem-col'>
                <div>
                    <Label>
                        Start
                    </Label>
                    <p>{moment(startDate).format('DD/MM/YY')}</p>
                </div>


            </Col>
            <Col className='projectitem-col'>
                <div>
                    <Label>End</Label>
                    <p>{moment(endDate).format('DD/MM/YY')}</p>
                </div>
            </Col>
            <Col className='projectitem-col'>
                {renderStatus()}
            </Col>
            <Col className='projectitem-col'>
                <Button className='projectitem-btn' onClick={() => history.push(`/tender/view/${_id}`)}>Details</Button>
            </Col>

        </Row>
    )
}